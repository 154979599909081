<script setup>
import { provide, readonly } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import AppToast from './components/AppToast.vue'
import { toasts, toast } from './functions/toast.js'
import Api from './functions/api.js'

const router = useRouter()
const route = useRoute()
const api = new Api(router)

const initCsrfToken = () => {
  api.GET('/sanctum/csrf-cookie')
}

provide('router', router)
provide('route', route)
provide('toasts', readonly(toasts))
provide('toast', toast)
provide('api', api)

initCsrfToken()
</script>

<template>
  <router-view />
  <AppToast/>
</template>