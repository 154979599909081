import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// global stuffs
import globalComponents from './components'
import globalDirectives from './directives'

// styles
import './assets/tailwind.css'

// helper functions
import Api from './functions/api.js'
import { registerSW } from 'virtual:pwa-register'
import formatter from './functions/formatters'
registerSW()

const app = createApp(App)
app.config.globalProperties.fmt = formatter
app.config.globalProperties.api = new Api(router)
app.config.performance = true

app.use(router)
  .use(globalComponents)
  .use(globalDirectives)

app.mount('#app')
