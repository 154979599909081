// https://www.npmjs.com/package/@fortawesome/vue-fontawesome

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowRightArrowLeft,
  faArrowRotateLeft,
  faArrowRotateRight,
  faEye,
  faEyeSlash,
  faFloppyDiskCircleArrowRight,
  faLockKeyhole,
  faRightFromBracket,
  faTrash,
  faUser,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faArrowRightArrowLeft,
  faArrowRotateLeft,
  faArrowRotateRight,
  faEye,
  faEyeSlash,
  faFloppyDiskCircleArrowRight,
  faLockKeyhole,
  faRightFromBracket,
  faTrash,
  faUser,
)

export default {
  install (Vue) {
    Vue.component('FaIcon', FontAwesomeIcon)
    Vue.component('FaLayers', FontAwesomeLayers)
    Vue.component('FaLayersText', FontAwesomeLayersText)
  },
}