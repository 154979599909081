<template>
  <button :type="type" :disabled="disabled || loading">
    <slot></slot>
    <span v-if="loading" class="absolute dot"></span>
  </button>
</template>

<script>
export default {
  name: 'ButtonVue',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="postcss" scoped>
.dot:after {
  animation: dotty steps(1,end) 1s infinite;
  content: '';
}
</style>
<style>
@keyframes dotty {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}
</style>