import FontAwesome from './FontAwesome'
import Button from './Button.vue'
import ErrorMsg from './ErrorMessage.vue'

export default {
  install (Vue) {
    Vue.component('GButton', Button)
    Vue.component('GErrorMsg', ErrorMsg)
    Vue.use(FontAwesome)
  },
}