<template>
  <div class="fixed inset-x-0 flex flex-col pointer-events-none bottom-12">
    <transition-group name="toast">
      <div
        v-for="toast in toasts"
        :key="toast.id"
        class="max-w-screen-sm px-4 py-1 mx-auto mb-2 text-xs text-white rounded-full toast-items">
        {{ toast.message }}
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  inject: ['toasts'],
}
</script>

<style scoped>
  .toast-items {
    background: #000000AA;
    transition: all 0.8s ease;
  }

  .toast-enter-from,
  .toast-leave-to {
    opacity: 0;
  }
</style>